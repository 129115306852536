<template>
  <div>
    <div class="page-title">修改信息</div>
    <div class="box">
      <el-form
        ref="form"
        :model="form"
        size="small"
        :rules="rules"
      >
        <el-form-item>
          <div class="labelleft">
            <span class="xinghao" style="margin:5.5px"></span>
            <span>帐号</span>
          </div>
          <p>{{ form.account }}</p>
        </el-form-item>
        <el-form-item prop="password">
          <div class="labelleft">
            <span class="xinghao">*</span>
            <span>密码</span>
          </div>
          <el-input class="width340" v-model="form.password" placeholder="如无修改无需填写" clearable type="password" show-password />
        </el-form-item>
        <el-form-item prop="email">
          <div class="labelleft">
            <span class="xinghao" style="margin:5.5px"></span>
            <span>邮箱</span>
          </div>
          <el-input class="width340" v-model="form.email" style="300px" clearable />
        </el-form-item>
        <el-form-item prop="telephone">
          <div class="labelleft">
            <span class="xinghao">*</span>
            <span>手机号码</span>
          </div>
          <el-input class="width340" v-model="form.telephone" style="300px" clearable />
        </el-form-item>
        <el-form-item>
          <div class="labelleft">
            <span class="xinghao">*</span>
            <span class="validatecss">验证码</span>
          </div>
          <el-input class="width215" v-model="form.valicode" placeholder="请输入手机验证码" />
          <el-button class="clickTipBtn" :disabled="disabled" :loading="phoneLoading" @click="sendEmail">{{ sendTips }}</el-button>
        </el-form-item>
        <el-form-item style="text-align:center">
          <el-button size="medium" type="primary" @click="$router.push('/home/basic-information')">返回首页</el-button>
          <el-button size="medium" type="primary" :loading="subLoading" @click="handleUpdate">修改</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { setCookie, getCookie, removeCookie } from '@/utils/auth'
import { modifyPersonalInfo, sendValidateCode } from '@/api'
export default {
  components: {},
  props: {},
  data () {
    const validatePass = (rule, value, callback) => {
      let regNumber = /\d+/; //验证0-9的任意数字最少出现1次。
      let regString = /[a-zA-Z]+/; //验证大小写26个字母任意字母最少出现1次。
      if (value) {
        if (value.length < 8) {
          callback(new Error('密码不得少于8位'))
        } else if (!regNumber.test(value) || !regString.test(value)) {
          callback(new Error('密码必须包含数字和英文'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    const validatePhone = (rule, value, callback) => {
      let reg = /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
      if (value === '') {
        callback(new Error('请输入手机号码'))
      } else if (!reg.test(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        callback()
      }
    }
    return {
      subLoading: false,
      visible: false,
      form: {
        account: '',
        email: '',
        password: '',
        account_type: '',
        uid: null,
        valicode: null,
        telephone: ''
      },
      rules: {
        email: [{ type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: 'change' }],
        telephone: [
          { validator: validatePhone, trigger: 'change' }
        ]
      },
      sendTips: '获取验证码',
      phoneLoading: false, // 验证码按钮加载动画
      disabled: false // 获取验证码按钮是否可按
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {
    // console.log('this.$store.state.user', this.$store.state.user)
    this.form.account = this.$store.state.user.username
    this.form.email = this.$store.state.user.email
    this.form.telephone = this.$store.state.user.telephone
    // console.log('this.$store.state.user.username', this.$store.state.user.username)
  },
  methods: {
    // 修改
    handleUpdate () {
      // eslint-disable-next-line consistent-this
      const that = this
      that.$refs.form.validate((valid) => {
        if (valid) {
          // console.log('getCookie(sentPhone)', getCookie('sentPhone'))
          // 如果没有获取验证码
          if (that.sendTips === '获取验证码' && getCookie('sentPhone') === undefined) {
            that.$message({
              message: '请先获取验证码',
              type: 'error',
              duration: 5 * 1000
            })
            return 0
          }
          if (!that.form.valicode) {
            that.$message({
              message: '请输入验证码',
              type: 'error',
              duration: 5 * 1000
            })
            return
          }
          that.subLoading = true

          const data = {
            'oldEmail': that.$store.state.user.email,
            'email': that.form.email ? that.form.email : null,
            'password': that.form.password ? that.form.password : '',
            'telephone': that.form.telephone,
            'valicode': that.form.valicode
          }

          // if (data) {
          //   return console.log('that.form.password', that.form.password)
          // }

          /* 发起请求 */
          modifyPersonalInfo({
            data
          }).then(res => {
            that.$message({
              message: res.message || 'Success',
              type: 'success',
              duration: 5 * 1000
            })
            removeCookie('sentPhone')
            if (that.form.password) {
              setTimeout(() => {
                that.logout()
              }, 1000)
            } else {
              setCookie('email', that.form.email)
              setTimeout(() => {
                this.$router.push('/home')
              }, 1000)
            }

            that.disabled = false
            that.phoneLoading = false

          }).catch((res) => {
            that.$message({
              message: res,
              type: 'error',
              duration: 5 * 1000
            })
            console.log('error', res)
          }).finally(() => {
            that.subLoading = false
          })
        }
      })
    },
    // 退出帐号
    logout () {
      this.$store.dispatch('LogOut').then((res) => {
        this.$router.push('/signin')
      }).catch(error => {
        this.$message.error(error);
      })
    },
    // 发送验证码
    sendEmail () {
      // eslint-disable-next-line consistent-this
      const that = this
      this.$refs.form.validate((valid) => {
        if (valid) {
          that.phoneLoading = true
          that.disabled = true
          if (that.sendTips !== '获取验证码') {
            return 0
          }

          /* 发起请求 */
          sendValidateCode({
            telephone: this.form.telephone,
            type: 1
          }).then(res => {
            that.$message({
              // eslint-disable-next-line prefer-template
              message: res.data.message + '至' + that.form.telephone + '手机号码',
              type: 'success',
              duration: 5 * 1000
            })
            that.phoneLoading = false

            setCookie('sentPhone', true)

            let time = 60

            // eslint-disable-next-line prefer-template
            that.sendTips = time + '秒'

            const timer = setInterval (() => {
              // eslint-disable-next-line no-const-assign
              time--
              // eslint-disable-next-line prefer-template
              that.sendTips = time + '秒'

              if (time === 0) {
                // eslint-disable-next-line no-const-assign
                time = 60
                clearInterval(timer)
                that.sendTips = '获取验证码'
                that.phoneLoading = false
                that.disabled = false
              }
            }, 1000)
          }).catch((res) => {
            that.$message({
              message: res,
              type: 'error',
              duration: 5 * 1000
            })
            console.log('error', res)
            that.disabled = false
            that.phoneLoading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
p{
  margin: 0;
}

.page-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  padding: 25px 0px;
}

.box{
  width: 500px;
  height: 370px;
  border-radius: 15px;
  border:1px solid #babbbd;
  margin: 0 auto 20px auto;
  box-shadow: #eee 1px 2px;
}
.common-applicants__edit >>> .el-dialog {
  width: 500px;
  max-width: 90%;
}
.input-with-select >>> .el-input-group__prepend {
  background-color: #fff;
}

.clickTipBtn {
  margin-left:12px;
  width: 107px;
}

.width215{
  width:215px;
}

.el-form {
  width: 420px;
  margin: 20px auto;
}

.xinghao {
  color:red;
  display: inline-block;
  margin: 1px 5px 0 0;
}

.labelleft {
  float: left;
  width: 80px;
}

.width340 {
  width: 335px;
}

.el-form-item{
  margin-bottom: 23px;
}

/* .el-input__inner {
  height: 30px;
  line-height: 30px;
} */

/* .el-button{
  height: 41px;
  padding: 0 15px;
} */

@media screen and (max-width: 520px) {
  .box {
    width: 100%;
    padding: 0 20px;
  }
  .el-form {
    width: 100%;
  }
  .width340 {
    width: 235px
  }
  .width215 {
    width: 125px;
  }
  .clickTipBtn {
    width: 97px;
  }
}
</style>
